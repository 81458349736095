import React, { useState, useEffect, useContext, useCallback } from 'react';
import { LojaContext } from '~/context/loja';

import api from '~/services/api';

import history from '~/services/history';
import {
  setUserToken,
  setExpirationToken,
  getUserToken,
  removeExpirationToken,
  removeUserToken,
  expiredToken,
  setUserData,
  setNotification,
  removeUserData,
  getUserData,
} from '~/services/user';

import { useQueryClient } from 'react-query';

interface controleAcessoTela {
  cod_controle: number;
  cod_tela: number;
  flg_read: boolean;
}

interface lojaData {
  cod_loja: number;
  des_loja: string;
  des_uf: string;
  tipo_integra_frente: number;
  tipo_regime: number;
}

export interface User {
  id: number;
  email: string;
  usuario?: string;
  loja?: number;
  cod_controle?: number;
  des_foto?: string;
  controleAcessoTela: controleAcessoTela[];
  tipo_regime?: number;
  flg_admin: boolean;
  flg_superadmin: boolean;
  loja_data: lojaData;
  num_cpf_cnpj: string;
  num_celular: string;
  timezone: string;
  cod_pessoa: number;
  flg_lib_financeiro?: boolean;
  precos_arredondamentos: any[];
}

interface ResponseLogin {
  success: boolean;
  token?: string;
  data?: {
    id: number;
    email: string;
    name: string;
    usuario?: string;
    loja?: number;
    cod_controle?: number;
    des_foto?: string;
    controleAcessoTela: controleAcessoTela[];
    flg_superadmin: boolean;
    flg_admin: boolean;
    loja_data: lojaData;
    num_cpf_cnpj: string;
    num_celular: string;
    timezone: string;
    cod_pessoa: number;
    precos_arredondamentos: any[];
  };
  expiresIn?: number;
  message?: string;
}

export default function useAuth() {
  const [authenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState<User>({} as User);

  const [loading, setLoading] = useState(true);
  const { changeLoja } = useContext(LojaContext);
  const queryClient = useQueryClient();

  useEffect(() => {
    const token = getUserToken();
    const userData = getUserData();

    if (userData) {
      setUser(userData);
      changeLoja(Number(userData.loja));
    }
    if (token) {
      if (expiredToken()) {
        setLoading(false);
        setAuthenticated(false);
        return;
      }

      api.defaults.headers.Authorization = `Bearer ${token}`;
      setAuthenticated(true);
    }
    setLoading(false);
  }, []);

  const handleChangeUserData = useCallback(
    (userData: User) => {
      setUser(userData);
      setUserData(userData);
      if (userData.loja) {
        changeLoja(userData.loja);
      }
    },
    [changeLoja],
  );

  const handleNotificacao = useCallback((userData: User) => {
    setNotification(userData);
  }, []);

  // }, [changeLoja, setUser]);

  const handleLogin = async (
    email: string,
    password: string,
  ): Promise<void | string> => {
    const { data } = await api.post<ResponseLogin>('/session', {
      email,
      password,
    });

    if (data.token && data.expiresIn && data.data) {
      // set the user's token
      setUserToken(data.token);

      // stores the expiration date of the token
      setExpirationToken(data.expiresIn);

      // stores user data
      setUserData(data.data);
      // stores user data
      setUser(data.data);

      api.defaults.headers.Authorization = `Bearer ${data.token}`;

      setAuthenticated(true);

      data.data.loja && changeLoja(Number(data.data.loja));

      history.push('/app');
    }
  };

  const handleLogout = (screens: any = []) => {
    if (screens.length > 1) {
      const confirm = window.confirm(
        'Existem telas abertas, ao sair os dados da tela serão perdidos, deseja continuar?',
      );
      if (confirm) {
        setAuthenticated(false);

        removeUserToken();
        removeExpirationToken();
        removeUserData();

        api.defaults.headers.Authorization = undefined;

        // Remove todas as consultas do cache
        queryClient.clear();

        history.push('/');
      }
    } else {
      setAuthenticated(false);

      removeUserToken();
      removeExpirationToken();
      removeUserData();

      api.defaults.headers.Authorization = undefined;

      history.push('/');
    }
  };

  return {
    authenticated,
    loading,
    handleLogin,
    handleLogout,
    user,
    setUser,
    handleChangeUserData,
    handleNotificacao,
  };
}
