import { useState } from 'react';

interface Macro {
  cod_tipo_info: number;
  items: Array<{
    cod_item: number;
    valor: any;
    des_item: string;
  }>;
}

export interface IUseMacro {
  macros: Macro[];
  addMacro: (data: Macro) => void;
  getMacro: (cod_tipo_info: number) => Macro | undefined;
}

export default function useMacro(): IUseMacro {
  const [macros, setMacros] = useState<Macro[]>([]);

  const addMacro = (data: Macro) => {
    setMacros((items) => [...items, data]);
  };

  const getMacro = (cod_tipo_info: number) => {
    const findMacro = macros.find((i) => i.cod_tipo_info === cod_tipo_info);
    return findMacro;
  };

  return {
    macros,
    addMacro,
    getMacro,
  };
}
