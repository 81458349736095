import { useState } from 'react';
import { Filter } from '../components/Search/types';

export interface IFilter {
  codTela: number;
  edit: boolean;
  flg_pesquisado?: boolean;
  cod_card?: number;
  name_card: string;
  cor?: string;
  itens: Filter[];
}
export interface FilterDefaultProps {
  codTela: number;
  itens: {
    index: string;
    cod_campo: number;
    cod_tipo_dados: number;
    cod_tipo_info: number;
    condicao: number;
    des_valor: string;
  }[];
}

export interface IUseFilter {
  filtersContexto: IFilter[];
  AddFilterOrUpdate: (filtros: any) => void;
  RemoveAllFilter: (codTela: number) => void;
  RemoveAllFilterPages: () => void;
  RemoveFilter: (codTela: number, index: string) => void;
  RemoveFilterisBlock: (codTela: number) => void;
  RemoveFilterEmptyByIndex: (index: string) => void;
  AddFilterDefault: (item: FilterDefaultProps) => void;
  filterDefauts: FilterDefaultProps[];
}

export default function useFilter(): IUseFilter {
  const [filtersContexto, setFiltersContexto] = useState<IFilter[]>([]);
  const [filterDefauts, setFilterDefauts] = useState<FilterDefaultProps[]>([]);

  function AddFilterDefault(filterItem: FilterDefaultProps) {
    const filterAll = filterDefauts;
    const filters = filterAll.filter(
      (item) => item.codTela !== filterItem.codTela,
    );
    setFilterDefauts([...filters, filterItem]);
  }
  function AddFilterOrUpdate(filterItem: any) {
    const { codTela, itens } = filterItem;
    const AllfiltersScreen = filtersContexto;

    const IndexFilter = AllfiltersScreen.findIndex(
      (item: IFilter) => item.codTela === codTela,
    );

    if (IndexFilter !== -1) {
      const itensContexto = AllfiltersScreen[IndexFilter].itens;
      // Percorrendo todos itens filtros
      itens.forEach((item: Filter) => {
        // Verificando se existe item dentro filtro dessa tela
        const IndexItemContexto = itensContexto.findIndex(
          (itemContext: Filter) => itemContext.index === item.index,
        );
        // Se existir vou substituir se não vou adicionar
        if (IndexItemContexto !== -1) {
          itensContexto[IndexItemContexto] = item;
        } else {
          itensContexto.push(item);
        }
      });

      AllfiltersScreen[IndexFilter].itens = itensContexto;
      setFiltersContexto(AllfiltersScreen);
    } else {
      filterItem.itens = itens;
      const newFilterItem = { ...filterItem };

      AllfiltersScreen.push(newFilterItem);

      setTimeout(() => {
        setFiltersContexto(AllfiltersScreen);
      }, 405);
    }
  }

  function RemoveFilter(codTela: number, index: string) {
    const Allfilters = filtersContexto;

    const IndexFilter = Allfilters.findIndex(
      (item: IFilter) => item.codTela === codTela,
    );

    if (IndexFilter !== -1) {
      const { itens } = Allfilters[IndexFilter];
      const AllItens = itens.filter(
        (item) => `${item.index}${item.isBlock}` !== `${index}${false}`,
      );
      Allfilters[IndexFilter].itens = AllItens;
    }
    setFiltersContexto([...Allfilters]);
  }
  function RemoveFilterisBlock(codTela: number) {
    const Allfilters = filtersContexto;

    const IndexFilter = Allfilters.findIndex(
      (item: IFilter) => item.codTela === codTela,
    );

    if (IndexFilter !== -1) {
      const { itens } = Allfilters[IndexFilter];
      const AllItens = itens.filter((item) => item.isBlock === true);
      Allfilters[IndexFilter].itens = AllItens;
    }

    setFiltersContexto([...Allfilters]);
  }
  async function RemoveFilterEmptyByIndex(index: string) {
    const Allfilters = filtersContexto;
    // eslint-disable-next-line no-restricted-syntax
    for await (const filter of Allfilters) {
      const filterEmpty = filter.itens.filter((item) => item.index === index);
      if (filterEmpty[0]?.des_valor) {
        if (filterEmpty[0]?.des_valor >= 0) {
          return;
        }
        RemoveFilter(filter.codTela, index);
      }
    }
  }

  async function RemoveAllFilter(codTela: number) {
    const Allfilters = filtersContexto;
    setFiltersContexto([]);

    const filters = await Allfilters.filter(
      (item: IFilter) => item.codTela !== codTela,
    );
    // if (Allfilters[IndexFilter]?.itens) {
    //   Allfilters[IndexFilter].itens = [];
    // }
    // await Allfilters.splice(IndexFilter, 1);
    setFiltersContexto(filters);
  }

  async function RemoveAllFilterPages() {
    setFiltersContexto([]);
  }

  return {
    filtersContexto,
    AddFilterOrUpdate,
    RemoveAllFilter,
    RemoveAllFilterPages,
    RemoveFilter,
    RemoveFilterEmptyByIndex,
    RemoveFilterisBlock,
    AddFilterDefault,
    filterDefauts,
  };
}
