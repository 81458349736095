import { useState, useCallback, useContext } from 'react';
import { toast } from 'react-toastify';
import navmenu from '~/config/navmenu';
import { useHistory } from 'react-router-dom';
import api from '~/services/api';
import { useQueryClient } from 'react-query';
import { InformacaoTelasAcessadasContext } from '~/context/InformacaoTelasAcessadas';
import { FilterContext } from '~/context/filter';

export interface Screen {
  label: string;
  content: any;
  active?: boolean;
  lib_icon: string;
  des_icon: string;
  cod_tela: number;
  url: string;
  linkMaterial: string;
  flg_abre_pesquisa: boolean;
}

export interface IUseNavigation {
  screens: Screen[];
  addScreen: (data: Screen) => void;
  removeScreen: (label: string | undefined) => void;
  removeAllScreens: () => void;
  loader: boolean;
  setScreenActive(cod_tela: any): void;
  navmenu: any;
}

export default function useNavigation(): IUseNavigation {
  const history = useHistory();
  const [screens, setScreens] = useState<Screen[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const { addScreenInfo, removeScreenInfo } = useContext(
    InformacaoTelasAcessadasContext,
  );
  const { RemoveAllFilter } = useContext(FilterContext);

  const addScreen = useCallback(
    async (data: Screen) => {
      setLoader(true);
      if (screens.length >= 6) {
        const filteredScreens = screens.find(
          (item) => item.cod_tela === data.cod_tela,
        );
        if (!filteredScreens) {
          history.goBack();
          toast.warn(
            'Limite de telas abertas esgotado, feche uma das telas para poder abrir',
          );
          setLoader(false);

          return;
        }
      }

      const removeActives = screens.map((screen) => ({
        ...screen,
        active: false,
      }));

      const contemHome: any = screens.filter((telaActive) => {
        return Number(telaActive.cod_tela) === 204;
      });

      const InfoTelaHome: any = navmenu.filter((tela: any) => {
        return tela.id === 204;
      });

      const telaArray = [];
      if (contemHome.length === 0 && Number(data.cod_tela) !== 204) {
        const dataTela = await api.post('/tela-por-codigo', {
          cod_tela: 204,
        });
        if (dataTela.data.data && dataTela.data.success) {
          const tela = {
            cod_tela: InfoTelaHome[0].id,
            content: InfoTelaHome[0].content,
            des_icon: dataTela.data.data[0].des_icon,
            label: dataTela.data.data[0].label_menu,
            lib_icon: dataTela.data.data[0].lib_icon,
            url: InfoTelaHome[0].url,
            linkMaterial: dataTela.data.data[0].link_material,
            flg_abre_pesquisa: dataTela.data.data[0].flg_abre_pesquisa,
          };

          telaArray.push({ ...tela, active: false });
        }
      }

      const verifyOpenPage = screens.findIndex(
        (screen) => screen.cod_tela === data.cod_tela,
      );

      if (verifyOpenPage > -1) {
        removeActives[verifyOpenPage].active = true;
        setScreens(removeActives);
      } else if (screens.length === 0) {
        telaArray.push({ ...data, active: true });
        setScreens(telaArray);
      } else {
        setScreens([...removeActives, { ...data, active: true }]);
      }

      setLoader(false);
    },
    [screens],
  );

  const removeScreen = useCallback(
    (label: string | undefined) => {
      setLoader(true);
      // Excluir Screen
      const filtered = screens.filter(
        (screen: Screen) => screen.label !== label,
      );
      // Se existe filtrado
      const existActiveIndex = filtered.findIndex(
        (screen: Screen) => screen.active === true,
      );
      // Index filtrado
      const MaxScreen = filtered.length > 0 ? filtered.length - 1 : 0;
      const IndexActiveScreen =
        existActiveIndex !== -1 ? existActiveIndex : MaxScreen;
      // Removendo Ativos
      const removeActives = filtered.map((screen) => ({
        ...screen,
        active: false,
      }));
      // Ativando
      if (filtered.length >= 1) {
        removeActives[IndexActiveScreen].active = true;
        history.push(removeActives[IndexActiveScreen].url);
      }
      setScreens(removeActives);
      setLoader(false);
      if (filtered.length <= 0) {
        history.push('/app');
      }
    },
    [history, screens],
  );

  const removeAllScreens = useCallback(() => {
    setLoader(true);
    const homeScreen = screens.filter((screen: Screen) => screen.url === 'app');

    screens.forEach((screen) => {
      if (screen.url !== 'app') {
        queryClient.removeQueries(`cache_tela_${screen.cod_tela}`);
        queryClient.removeQueries(`infoCardAtivo_${screen.cod_tela}`);
        queryClient.removeQueries(`cacheLastSearch_${screen.cod_tela}`);
      }
    });

    setScreens(homeScreen);
    setLoader(false);
    if (!homeScreen[0]?.active) {
      history.push('/app');
    }
  }, [screens]);

  const setScreenActive = useCallback(
    (cod_tela) => {
      const removeActives = screens.map((screen) => ({
        ...screen,
        active: false,
      }));

      const indexPage = screens.findIndex(
        (screen) => screen.cod_tela === cod_tela,
      );

      removeActives[indexPage].active = true;
      history.push(removeActives[indexPage].url);
      setScreens(removeActives);
    },
    [screens],
  );

  return {
    screens,
    addScreen,
    removeScreen,
    removeAllScreens,
    loader,
    setScreenActive,
    navmenu,
  };
}
