import { useQuery, UseQueryResult } from 'react-query';

interface DataResponse {
  data: any[];
}

interface UseCacheTelasProps {
  codTela: number;
  page: number;
  enabledCaching?: boolean;
  handleSearch: () => Promise<DataResponse>;
}

/**
 * @param codTela Código da tela em que será gerado o cache de dados
 * @function handleSearch Callback que efetua a comunicação com a api
 * @param page Pagina selecionada da tabela (referente a paginação)
 * @param enabledCaching Informa se o cache deve ser gerado ou não
 * (inicialmente vem false, pois espera a primeira ação do usuário para efetuar a comunicação com a api)
 * @returns DataResponse | undefined
 */
export function useCacheTelas({
  codTela,
  handleSearch,
  page,
  enabledCaching = false,
}: UseCacheTelasProps): UseQueryResult<DataResponse, unknown> {
  return useQuery([`cache_tela_${codTela}`, page], () => handleSearch(), {
    staleTime: 1000 * 60,
    enabled: enabledCaching,
    refetchOnWindowFocus: false,
  });
}
