import { useEffect, useState } from 'react';

type UseMediaQueryProps = {
  minWidth?: number;
  maxWidth?: number;
};

export const useMediaQuery = ({
  minWidth,
  maxWidth,
}: UseMediaQueryProps): boolean => {
  const [isWidthInRange, setIsWidthInRange] = useState(false);

  const checkWidth = () => {
    const windowWidth = window.innerWidth;

    if (minWidth && maxWidth)
      setIsWidthInRange(windowWidth >= minWidth && windowWidth <= maxWidth);
    else if (minWidth) setIsWidthInRange(windowWidth >= minWidth);
    else if (maxWidth) setIsWidthInRange(windowWidth <= maxWidth);
    else setIsWidthInRange(true);
  };

  useEffect(() => {
    const handleResize = () => {
      checkWidth();
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [minWidth, maxWidth]);

  return isWidthInRange;
};
