import { useState } from 'react';

export interface ILoadingSearch {
  key: number;
  value: boolean;
}

export interface IUseLoading {
  loading: boolean;
  setLoading: (val: boolean) => void;
  setLoadingTableSearch: (val: ILoadingSearch[]) => void;
  loadingTableSearch: ILoadingSearch[];
  setLoadingPageSearch: (val: ILoadingSearch[]) => void;
  loadingPageSearch: ILoadingSearch[];
  setLoadingNotificaoPage: (val: boolean) => void;
  setLoadingNotificaoTable: (val: boolean) => void;
  loadingNotificaoPage: boolean;
  loadingNotificaoTable: boolean;
  handleLoadingPageSearch: (val: boolean, cod_tela: number) => void;
  handleLoadingPageSearchAll: (loading2: ILoadingSearch[]) => void;
  handleLoadingPageSearchRemove: (cod_tela: number) => void;

  handleLoadingTableSearch: (val: boolean, cod_tela: number) => void;
  handleLoadingTableSearchAll: (loading2: ILoadingSearch[]) => void;
  handleLoadingTableSearchRemove: (cod_tela: number) => void;
  setLoadingDepartamento: (val: boolean) => void;
  loadingDepartamento: boolean;

  setLoadingCategoriaFinanceira: (val: boolean) => void;
  loadingCategoriaFinanceira: boolean;

  loadingNavBarNotificacao: boolean;
  setLoadingNavBarNotificacao: (val: boolean) => void;
  setLoadingCard: (val: boolean) => void;
  loadingCard: boolean;
}

/**
 * Utilizado para controlar o estado de carregamento na aplicação.
 * @param loading boolean indicando o estado do indicador do carregamento
 * @param setLoading Altera o estado de carregamento
 */

export default function useLoading(): IUseLoading {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingTableSearch, setLoadingTableSearch] = useState<
    ILoadingSearch[]
  >([]);
  const [loadingPageSearch, setLoadingPageSearch] = useState<ILoadingSearch[]>(
    [],
  );

  const [loadingNotificaoPage, setLoadingNotificaoPage] =
    useState<boolean>(false);
  const [loadingNotificaoTable, setLoadingNotificaoTable] =
    useState<boolean>(false);

  const [loadingDepartamento, setLoadingDepartamento] =
    useState<boolean>(false);

  const [loadingCategoriaFinanceira, setLoadingCategoriaFinanceira] =
    useState<boolean>(false);

  const [loadingNavBarNotificacao, setLoadingNavBarNotificacao] =
    useState<boolean>(false);

  const [loadingCard, setLoadingCard] = useState<boolean>(false);

  const handleLoadingPageSearch = (val: boolean, cod_tela: number) => {
    const loadingPageIndex = loadingPageSearch.findIndex(
      (page) => page.key === cod_tela,
    );
    let loadingTelas = loadingPageSearch;
    if (loadingPageIndex === -1) {
      loadingTelas = [...loadingTelas, { key: cod_tela, value: val }];
    } else {
      loadingTelas[loadingPageIndex].value = val;
    }

    setLoadingPageSearch([]);
    setLoadingPageSearch(loadingTelas);
  };
  const handleLoadingPageSearchAll = (loading2: ILoadingSearch[]) => {
    const loadingTelas = loading2;
    for (let i = 0; i < loadingTelas.length; i++) {
      loadingTelas[i].value = false;
    }
    setLoadingPageSearch([]);
    setTimeout(() => {
      setLoadingPageSearch(loadingTelas);
    }, 1000);
  };
  const handleLoadingPageSearchRemove = (cod_tela: number) => {
    const loadingTelas: ILoadingSearch[] = [];
    for (let i = 0; i < loadingPageSearch.length; i++) {
      if (loadingPageSearch[i].key !== cod_tela) {
        loadingTelas.push(loadingPageSearch[i]);
      }
    }

    setLoadingPageSearch([]);
    setLoadingPageSearch(loadingTelas);
  };

  // loading da tabela
  const handleLoadingTableSearch = (val: boolean, cod_tela: number) => {
    const loadingTableIndex = loadingTableSearch.findIndex(
      (page) => page.key === cod_tela,
    );
    let loadingTelas = loadingTableSearch;
    if (loadingTableIndex === -1) {
      loadingTelas = [...loadingTelas, { key: cod_tela, value: val }];
    } else {
      loadingTelas[loadingTableIndex].value = val;
    }

    setLoadingTableSearch(loadingTelas);
  };
  const handleLoadingTableSearchAll = (loading2: ILoadingSearch[]) => {
    const loadingTelas = loading2;
    for (let i = 0; i < loadingTelas.length; i++) {
      loadingTelas[i].value = false;
    }

    setLoadingTableSearch([]);
    setLoadingTableSearch(loadingTelas);
  };
  const handleLoadingTableSearchRemove = (cod_tela: number) => {
    const loadingTelas: ILoadingSearch[] = [];
    for (let i = 0; i < loadingTableSearch.length; i++) {
      if (loadingTableSearch[i].key !== cod_tela) {
        loadingTelas.push(loadingTableSearch[i]);
      }
    }

    setLoadingTableSearch([]);
    setLoadingTableSearch(loadingTelas);
  };

  return {
    loading,
    setLoading,
    loadingTableSearch,
    setLoadingTableSearch,
    loadingPageSearch,
    setLoadingPageSearch,
    setLoadingNotificaoPage,
    setLoadingNotificaoTable,
    loadingNotificaoPage,
    loadingNotificaoTable,
    handleLoadingPageSearch,
    handleLoadingPageSearchAll,
    handleLoadingPageSearchRemove,
    handleLoadingTableSearch,
    handleLoadingTableSearchAll,
    handleLoadingTableSearchRemove,
    loadingDepartamento,
    setLoadingDepartamento,
    loadingCategoriaFinanceira,
    setLoadingCategoriaFinanceira,
    loadingNavBarNotificacao,
    setLoadingNavBarNotificacao,
    setLoadingCard,
    loadingCard,
  };
}
